<template>
  <div class="wx-con">
    <img src="https://tstres.to2025.com/m2/common/images/goumai.png" />
    <p class="success" @click="click += 1">授权成功</p>
    <p class="wx-txt">授权完成，想要获取最新推送，</p>
    <p class="wx-txt">敬请关注“工业汇”微信公众号</p>
    <div style="padding: 0 30px" v-if="click >= 10">
      <p class="wx-txt" v-for="(item, index) in logs" :key="index">{{item}}</p>
    </div>
  </div>
</template>

<script>
import { fetch } from "@/utils/request";

export default {
  name: 'GetOpenId',
  data() {
    return {
      code: '',
      state: '',
      url: '',
      decodeUrl: '',
      requestUrl: '',
      res: '',
      err: '',
      click: 0,
      logs: []
    }
  },
  created() {
    let url = window.location.href
    this.decodeUrl = decodeURIComponent(url)
    this.log("decodeUrl", this.decodeUrl)

    let url1 = this.decodeUrl.split('?')[1]
    let url2 = url1.split('#')[0]
    let url3 = url2.split('&')
    this.log("url1", url1)
    this.log("url2", url2)
    this.log("url3", url3)

    this.code = url3[0].split('=')[1]
    const oidState = url3[1].split('=')[1]
    this.log("oidState", oidState)
    this.state = oidState.split(';')[0]
    const environment = oidState.split(';')[1]
    this.log("environment", environment)
    switch (environment) {
      case "pre":
        this.requestUrl = 'http://edp-pre.gyh01.com:10160/account/wx/web/author'
        break;
      case "prod":
        this.requestUrl = 'https://edp.to2025.com/api/account/wx/web/author'
        break;
      case "prod_kunda":
        this.requestUrl = 'https://kdedp.to2025.com/api/account/wx/web/author'
        break;
      case "prod_demo":
        this.requestUrl = "https://edpdemo.to2025.com/api/account/wx/web/author"
        break;
      case "prod_bj":
        this.requestUrl = "https://bjedp.to2025.com/api/account/wx/web/author"
        break;
    }
    this.log("requestUrl", this.requestUrl)
    this.getQrcodeUrl()
  },
  methods: {
    log(...attr) {
      this.logs.push(attr.map(item => {
        if (typeof item === "object") {
          return JSON.stringify(item)
        }
        return item
      }).join("  "))
    },
    async getQrcodeUrl() {
      try {
        this.log("request_params", {
          state: this.state,
          code: this.code
        })
        const res = await fetch('post', this.requestUrl, {
          state: this.state,
          code: this.code
        })
        this.res = res
        if (res.status === 0) {
          window.location.href = 'https://mp.weixin.qq.com/s/KFI8qYKvDLGndgOsN7FSnQ'
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        console.log(e)
        this.err = e
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wx-con {
  width: 100%;
  height: 100vh;
  background: #fff;
  text-align: center;
}

.nav-btn {
  width: 70%;
  line-height: 51px;
  font-size: 17px;
  text-align: center;
  background-color: #08f;
  color: #fff;
  border-radius: 3px;
  margin: 27px auto 0;
  box-sizing: border-box;
}

img {
  width: 138px;
  height: 142px;
  margin: 100px auto 0;
}

.success {
  font-size: 20px;
  font-weight: bold;
  color: #0088ff;
  text-align: center;
  margin-top: 30px;
}

.wx-txt {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #4e4e4e;
}
</style>
